import Papa, { UnparseObject } from 'papaparse';
import { User } from 'src/constants/dataTypes';
import { Company } from 'src/store/clients/types';

/**
 * generates break recipient data for client/company into fields for csv
 */
export default class CSVUtils {
  static GetCSVUserWithCompanyCellData = (
    user: User | undefined,
    company: Company | undefined,
  ) => {
    const cellDataParts = [
      user?.fields.givenName || '',
      user?.fields.familyName || '',
      company?.fields.name || '',
      user?.fields.email || '',
    ];

    return cellDataParts.join('","');
  };

  static GetCSVUserCellData = (user: User | undefined) => {
    const cellDataParts = [
      user?.fields.givenName || '',
      user?.fields.familyName || '',
      user?.fields.email || '',
    ];

    return cellDataParts.join('","');
  };

  /**
   * method will trigger an action to download CSV in the browser
   * @param fileName formatted filename of the form to be exported
   * @param data 2-dimensional array of strings, the first item representing headers and the rest representing cell data
   */
  static exportDataToCsv = (
    fileName: string,
    data: string[][] | UnparseObject<unknown>,
  ) => {
    const unparsed = Papa.unparse(data, {
      quotes: (field: string) => {
        return (
          field.includes('\n') ||
          field.includes('\r') ||
          field.includes('\r\n') ||
          field.includes(',')
        );
      },
    });
    const element = document.createElement('a');
    element.setAttribute(
      'href',
      `data:text/csv;charset=utf-8,${encodeURIComponent(unparsed)}`,
    );
    element.setAttribute('download', fileName);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  // values that are used in CSV export for a user - first name, last name, and email
  static CSVUserHeaderColumns = 'First name","Last name","Email';

  // values that are used in CSV export for a user - first name, last name, company and email
  static CSVUserWithCompanyHeaderColumns =
    'First name","Last name","Company","Email';
}
