import { ApiMethods, ApiTags, appAPI } from '.';
import { alertSnackbar } from 'src/store/ui/actions';
import { ensureApiError } from 'src/utils/Errors';

type NoteEntityType = 'CLIENT' | 'COMPANY';

export interface Note {
  createdAt: string;
  creatorId: string;
  /* Contains the plaintext of the note's title */
  title: string;
  /* Contains the HTML representing the note's body */
  content: string;
  id: string;
  entityId: string;
  entityType: NoteEntityType;
  updatedDate: string;
}

interface CreateNoteParams {
  title: string;
  content: string;
  entityType: NoteEntityType;
  entityId: string;
}

interface UpdateNoteParams {
  id: string;
  content: string;
  title: string;
}

interface ListNotesParams {
  entityType: NoteEntityType;
  entityId: string;
  limit?: number;
  nextToken?: string;
}

interface ListNotesResponse {
  data: Note[];
  nextToken?: string;
}

export const notesApi = appAPI.injectEndpoints({
  endpoints: (build) => ({
    createNote: build.mutation<Note, CreateNoteParams>({
      query: (body) => ({
        path: '/v1/notes',
        method: ApiMethods.post,
        options: { body },
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          dispatch(alertSnackbar({ successMessage: 'Note created' }));
        } catch (err) {
          const { message } = ensureApiError(err);
          dispatch(alertSnackbar({ errorMessage: message }));
        }
      },
      invalidatesTags: (result) =>
        result ? [{ type: ApiTags.notes, id: 'LIST' }] : [],
    }),
    updateNote: build.mutation<Note, UpdateNoteParams>({
      query: ({ id, title, content }) => ({
        path: `/v1/notes/${id}`,
        method: ApiMethods.patch,
        options: { body: { title, content } },
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          dispatch(alertSnackbar({ successMessage: 'Note updated' }));
        } catch (err) {
          const { message } = ensureApiError(err);
          dispatch(alertSnackbar({ errorMessage: message }));
        }
      },
      invalidatesTags: (result) =>
        result
          ? [
              { type: ApiTags.notes, id: result.id },
              { type: ApiTags.notes, id: 'LIST' },
            ]
          : [],
    }),
    getNote: build.query<Note, { id: string }>({
      query: ({ id }) => ({
        path: `/v1/notes/${id}`,
        method: ApiMethods.get,
        options: {},
      }),
      providesTags: (result) =>
        result
          ? [
              { type: ApiTags.notes, id: result.id },
              { type: ApiTags.notes, id: 'LIST' },
            ]
          : [],
    }),
    listNotes: build.query<ListNotesResponse, ListNotesParams>({
      query: (params) => {
        return {
          path: `/v1/notes?entityId=${params.entityId}&entityType=${
            params.entityType
          }&limit=${params.limit}${
            params.nextToken ? `&nextToken=${params.nextToken}` : ''
          }`,
          method: ApiMethods.get,
          options: {},
        };
      },
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          const {
            data: { nextToken },
          } = await queryFulfilled;

          // keep fetching next pages while we have a nextToken
          let currentToken = nextToken;
          while (currentToken) {
            const response = await dispatch(
              notesApi.endpoints.listNotes.initiate(
                {
                  ...arg,
                  nextToken: currentToken,
                },
                { forceRefetch: true },
              ),
            ).unwrap();

            currentToken = response.nextToken;
          }
        } catch (err) {
          const { message } = ensureApiError(err);
          dispatch(alertSnackbar({ errorMessage: message }));
        }
      },
      providesTags: () => [{ type: ApiTags.notes, id: 'LIST' }],
    }),
    deleteNote: build.mutation<{ id: string }, { id: string }>({
      query: ({ id }) => ({
        path: `/v1/notes/${id}`,
        method: ApiMethods.del,
        options: {},
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          dispatch(alertSnackbar({ successMessage: 'Note deleted' }));
        } catch (err) {
          const { message } = ensureApiError(err);
          dispatch(alertSnackbar({ errorMessage: message }));
        }
      },
      invalidatesTags: (result) =>
        result
          ? [
              { type: ApiTags.notes, id: result.id },
              { type: ApiTags.notes, id: 'LIST' },
            ]
          : [],
    }),
  }),
});

export const {
  useCreateNoteMutation,
  useUpdateNoteMutation,
  useGetNoteQuery,
  useListNotesQuery,
  useDeleteNoteMutation,
} = notesApi;

export type NoteQueryOptions = {
  entityType: 'CLIENT' | 'COMPANY';
  entityId: string;
};
