import { ICellRendererParams } from '@ag-grid-community/core';
import { Status, StatusProps } from 'copilot-design-system';
import React from 'react';
import { convertTitleCase } from 'src/utils/StringUtils';

interface StatusCellRendererProps extends ICellRendererParams {
  getStatusVariant: (status: string) => StatusProps['status'];
}

export const StatusCellRenderer: React.FC<StatusCellRendererProps> = React.memo(
  ({ getStatusVariant, value }) => (
    <Status status={getStatusVariant(value)} label={convertTitleCase(value)} />
  ),
);
