import React from 'react';
import { IconButton } from 'copilot-design-system';
import clsx from 'clsx';

export const RightSidebarToggle = ({
  onToggle,
  isSidebarOpen,
  className,
}: {
  onToggle: (isOpen: boolean) => void;
  isSidebarOpen?: boolean;
  className?: string;
}) => {
  const handleToggle = () => {
    onToggle(!isSidebarOpen);
  };

  return (
    <IconButton
      size="sm"
      variant="secondary"
      label="Toggle Sidebar"
      icon={isSidebarOpen ? 'SidebarFilled' : 'Sidebar'}
      onClick={handleToggle}
      key="sidebar-toggle"
      className={clsx(className, {
        'bg-non-hover-border': isSidebarOpen,
      })}
    />
  );
};
