import React, { useDebugValue, useEffect } from 'react';
import { shallowEqual } from 'react-redux';
import { useGetNotificationsSettingsQuery } from 'src/services/api';
import { PortalConfigContext } from '../context';
import { useGetInboxNotificationsQuery } from '../services/api/inboxApi';
import { formatNotificationCount } from '../utils/NotificationsUtils';
import { useNotificationsCounts } from './useNotificationsCount';
import { useAppSelector } from 'src/hooks/useStore';

/**
 * A custom React hook that calculates the notification count for internal or client users
 * and updates the page title with the count and title.
 *
 * This hook uses data from the Redux store to determine notification counts and
 * uses the `useEffect` hook to update the document title based on the notification counts.
 *
 * @returns {void}
 */
export const useUserNotification = (isClientUser: boolean) => {
  // Fetch inbox notifications using the useGetInboxNotificationsQuery hook
  const { data: inboxNotifications = [] } = useGetInboxNotificationsQuery();
  const portalConfig = React.useContext(PortalConfigContext);

  const { data: notificationSettings } = useGetNotificationsSettingsQuery(
    undefined,
    {
      skip: isClientUser,
    },
  );

  // Extract relevant state properties using useSelector from the Redux store
  const { isClient, totalUnreadMessages, clients, internalUsers, companyMap } =
    useAppSelector(
      (state) => ({
        formCompletedNotifications:
          state.notifications.formCompletedNotifications || [],
        formRequestNotifications:
          state.notifications.formRequestNotifications || [],
        totalUnreadMessages: state.messages.totalUnreadMessages || 0,
        newInvoiceNotifications:
          state.notifications.newInvoiceNotifications || [],
        isClient: state.user.isClient,
        fileNotifications: state.notifications.fileNotifications,
        esigRequestNotifications: state.notifications.esigRequestNotifications,
        esigRequestCompleteNotifications:
          state.notifications.esigRequestCompleteNotifications,
        clients: state.clients.clients,
        internalUsers: state.users.internalUsers,
        companyMap: state.clients.companyDataMap,
      }),
      shallowEqual,
    );

  // Calculate the count of unread inbox notifications
  const notificationCounts = useNotificationsCounts(
    inboxNotifications,
    [...clients, ...internalUsers],
    companyMap,
  );

  /**
   * Calculates the notification count for internal users by summing up
   * the total unread messages, unread inbox notifications, and form notifications.
   *
   * @returns {number} The total notification count for internal users.
   */
  const getInternalUserNotificationCount = (): number => {
    const disableMessagesNotifications =
      notificationSettings?.notifyAbout.newMessages.disableInProduct;

    // only count unread messages when the in-product notifications are allowed
    const unreadMessages = disableMessagesNotifications
      ? 0
      : totalUnreadMessages;

    return unreadMessages + notificationCounts.unreadNotificationCount;
  };

  /**
   * Calculates the notification count for client users by summing up
   * the total unread messages, unread inbox notifications, billing notifications, file notifications and form notifications.
   *
   * @returns {number} The total notification count for client users.
   */
  const getClientUserNotificationCount = (): number => {
    return totalUnreadMessages + notificationCounts.unreadNotificationCount;
  };

  // Calculate the total notification count for client and internal users
  const totalClientNotifications = getClientUserNotificationCount();
  const totalInternalNotifications = getInternalUserNotificationCount();

  useDebugValue({
    totalClientNotifications,
    totalInternalNotifications,
    totalUnreadMessages,
  });

  // Update the document title based on the user type and notification counts
  useEffect(() => {
    const notificationCount = isClient
      ? totalClientNotifications
      : totalInternalNotifications;
    const pageTitle = isClient
      ? portalConfig.metadata?.title || portalConfig.name
      : portalConfig.metadata.titleOverride;

    document.title = `${
      notificationCount > 0
        ? `(${formatNotificationCount(notificationCount)})`
        : ''
    } ${pageTitle}`;
  }, [totalClientNotifications, totalInternalNotifications, portalConfig]);
};
