import React, { useEffect } from 'react';
import { Event, ExtendableGenerics } from 'stream-chat';
import { setTotalUnreadMessages } from 'src/store/messages/actions';
import { NotificationBadge } from 'src/legacy/components/UI/Badges';
import { useChatClient } from 'src/legacy/components/Chat/useChatClient';
import { useGetNotificationsSettingsQuery } from 'src/services/api/notificationsSettingsApi';
import { useAppDispatch, useAppSelector } from 'src/hooks/useStore';

export const MessagesNotificationBadge = () => {
  const dispatch = useAppDispatch();
  const chatClient = useChatClient();
  const isClientUser = useAppSelector((state) => state.user.isClient);
  const { data: notificationSettings } = useGetNotificationsSettingsQuery(
    undefined,
    {
      skip: isClientUser,
    },
  );
  const disabledProductNotification = notificationSettings?.disableInProduct;
  const disableMessagesNotifications =
    notificationSettings?.notifyAbout.newMessages.disableInProduct;

  const totalUnreadMessages = useAppSelector(
    (state) => state.messages.totalUnreadMessages,
  );

  const clientEventHandler = (event: Event<ExtendableGenerics>) => {
    if (event.unread_count !== undefined) {
      dispatch(setTotalUnreadMessages(event.unread_count));
    }
  };

  useEffect(() => {
    if (chatClient && chatClient.user) {
      // subscribe to chat client events to update the unread messages count
      if (!disabledProductNotification || !disableMessagesNotifications) {
        chatClient.on(clientEventHandler);
      }

      // initializing the unread message redux state on sidebar mount from client user data
      if (
        !totalUnreadMessages &&
        totalUnreadMessages !== 0 &&
        (!disabledProductNotification || !disableMessagesNotifications)
      ) {
        dispatch(
          setTotalUnreadMessages(chatClient.user.unread_count as number),
        );
      }
    }
  }, [chatClient, disabledProductNotification, disableMessagesNotifications]);

  if (disabledProductNotification || disableMessagesNotifications) return null;

  return (
    <NotificationBadge
      htmlId="messages-notification-badge"
      isSidebar
      showZero={false}
      content={totalUnreadMessages || 0}
    />
  );
};
