import { Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import React from 'react';
import FilesClient from 'src/clients/FilesClient';
import Button from 'src/legacy/components/Button';
import { RegularCardBox } from 'src/legacy/components/Cards/RegularCardBox';
import {
  getExtensionFromFileKey,
  getFileNameFromFileKey,
} from 'src/legacy/components/Files/helpers';
import { InboxEmptyState } from 'src/legacy/components/Inbox/InboxEmptyState';
import { InboxNotificationTable } from 'src/legacy/components/Inbox/InboxNotificationTable';
import MemoBaseTypography from 'src/legacy/components/Text/BaseTypography';
import {
  ENTITIES,
  CLIENT_APPS_PAGE,
  FOLDER_FILE_KEY,
  NOTIFICATION_TIMELINE_DRAWER_WIDTH,
  User,
} from 'src/constants';
import { InboxPageContext } from 'src/context/inboxPageContext';
import {
  InboxNotificationDetailItem,
  NotificationEventType,
} from 'src/entities/Notifications';
import history from 'src/history';
import { useExportFormResponse } from 'src/hooks/useExportFormResponse';
import { useGetInboxNotificationDetailsQuery } from 'src/services/api/inboxApi';
import { NotificationGroups } from 'src/store/notifications/types';
import { DarkFontColor, NonHoverBackground } from 'src/theme/colors';
import { RightSidebarShadow } from 'src/theme/shadows';
import { FileUtils } from 'src/utils';
import S3Utils from 'src/utils/S3Utils';
import { ChannelContentToolbar } from '../Channels/ChannelContentToolbar';
import { FormResponseDetail } from '../FormsV2/FormResponseDetail';
import { LoadingWrapper } from '../Loading';
import { RightSidebar } from '../RightSidebar';
import { RightSidebarToggle } from '../../../components/RightSidebarToggle';
import { NotificationDetailsContainer } from './NotificationDetailsContainer';
import { NotificationDetailsHeaderActions } from './NotificationDetailsHeaderActions';
import { NotificationPdfViewer } from './NotificationPdfViewer';
import { LINK_FILE_EXTENSION } from './NotificationTableActionRenderer';
import { NotificationTimeline } from './NotificationTimeline';
import { useInboxNotification } from './useInboxNotification';
import { useGetInstallsQuery } from 'src/services/api/applicationsApi';
import { getFormCompletedResourceIds } from 'src/legacy/components/Inbox/InboxSidebar';
import useNavigate from 'src/hooks/useNavigate';
import { toggleRightSidebar } from 'src/store/ui/actions';
import { useAppSelector } from 'src/hooks/useStore';
import { useAppDispatch } from 'src/hooks/useStore';

const LegacyNotificationTypeToNotificationGroup: Partial<
  Record<NotificationGroups, NotificationEventType>
> = {
  [NotificationGroups.FileUploadNotification]: 'files.new',
  [NotificationGroups.EsigRequestCompleteNotifications]: 'esign.completed',
};

export interface NotificationTableRowData extends User {
  rowId: string;
  name: string;
  location: string;
  channelId: string;
  userName: string;
  isDeleted?: boolean;
  fileExtension?: string;
  // these fields are used for file preview
  fileIdentityId?: string;
  fileKey?: string;
  fileUrl?: string; // used to open links
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 'calc(100% - 360px)',
      height: 'calc(100% - 60px)',
      padding: 0,
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
    previewContainer: {
      height: '--webkit-fill-available',
      width: '100%',
      overflowY: 'scroll',
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
      // when it is file notification preview then we dont want
      // to add padding because the table should not have gutters on
      // the sides.
      padding: (props: { isFileNotification: boolean }) =>
        props.isFileNotification ? 0 : theme.spacing(2.5),
    },
    notificationTimelineDrawer: {
      padding: theme.spacing(3, 0),
      width: NOTIFICATION_TIMELINE_DRAWER_WIDTH,
      boxShadow: RightSidebarShadow,
    },
    rightSidebarToggle: {
      border: `1px solid ${NonHoverBackground}`,
      height: 28,
      width: 28,
      backgroundColor: 'white',
      marginLeft: theme.spacing(1.75),
      [theme.breakpoints.down('xs')]: {
        marginLeft: theme.spacing(1),
      },
    },
    customAppContentContainer: {
      padding: theme.spacing(3),
    },
    customAppCtaButton: {
      marginTop: '17px',
      textDecoration: 'none',
    },
    appLink: {
      textDecoration: 'none',
      color: DarkFontColor,
    },
  }),
);

export const DELETED_FILE_NOTIFICATION_STATUS = '0_CANCELLED';

export const InboxContent = () => {
  const dispatch = useAppDispatch();
  const isSidebarOpen = useAppSelector((state) => state.ui.rightSideBar.isOpen);
  const { selectedNotification, setSelectedNotification } =
    React.useContext(InboxPageContext);
  const { deleteNotification } = useInboxNotification();
  const { navigate } = useNavigate();

  const classes = useStyles({
    isFileNotification:
      selectedNotification?.notificationEventType === 'files.new' ||
      selectedNotification?.notificationEventType === 'files.created',
  });
  const previewContainerRef = React.useRef<HTMLDivElement>(null);

  const isFileUploadNotification =
    selectedNotification &&
    selectedNotification.notificationGroup === ENTITIES.NOTIFY_FILE_UPLOADED;

  const [notificationTableRowData, setNotificationTableRowData] =
    React.useState<NotificationTableRowData[]>([]);
  const {
    data: selectedNotificationDetails,
    isFetching: selectedNotificationDetailsLoading,
  } = useGetInboxNotificationDetailsQuery(
    {
      id: encodeURIComponent(selectedNotification?.id || ''),
    },
    {
      skip: !selectedNotification?.id,
      refetchOnMountOrArgChange: true,
    },
  );

  const { data: { extensionsSettings } = {} } = useGetInstallsQuery();

  const [
    filesNotificationDetailsDataReady,
    setFilesNotificationDetailsDataReady,
  ] = React.useState(false);

  const [invoiceS3AssetUrl, setInvoiceS3AssetUrl] = React.useState('');
  const [contractS3AssetUrl, setContractS3AssetUrl] = React.useState('');

  /**
   * This function will setup notification details view with data
   * based on selected notification and its type.
   * When it is contract or invoice notification, it will fetch
   * the contract/invoice pdf from s3 to be rendered in the pdf viewer.
   * When it is file upload notification, it will fetch the files related
   * to the notification channel id and then setup the notification table.
   */
  const setupNotificationDetailsView = async () => {
    // Getting contract or invoice notification pdf url
    if (
      selectedNotificationDetails?.event === 'invoice.paid' ||
      selectedNotificationDetails?.event === 'esign.completed' ||
      selectedNotificationDetails?.event === 'contract.signed'
    ) {
      switch (selectedNotificationDetails.event) {
        case 'invoice.paid':
          let invoiceNotificationFileUrl = '';
          invoiceNotificationFileUrl = await S3Utils.getFileUrlWithSignedKey(
            selectedNotificationDetails?.assetS3Key ?? '',
            '/v0/invoices/download/signedUrl',
          );
          setInvoiceS3AssetUrl(invoiceNotificationFileUrl);
          break;
        // todo @akash remove event after backfill
        case 'esign.completed':
        case 'contract.signed':
          let notificationContractS3Key = '';
          notificationContractS3Key = await S3Utils.getFile(
            selectedNotificationDetails.assetS3Key ?? '',
            {
              identityId: selectedNotificationDetails.identityId,
              level: 'protected',
            },
          );
          setContractS3AssetUrl(notificationContractS3Key);
          break;
      }
      // when it is contract or invoice notification then we don't need to
      // setup file notification table data. So we can skip the rest of the logic.
      return;
    }

    setFilesNotificationDetailsDataReady(false);
    // clearing last selected notification data
    setNotificationTableRowData([]);

    // don't do anything if no notification is selected
    if (!selectedNotification || !selectedNotification.channelId) return;

    const allChannelFiles = await FilesClient.GetFilesForChannelId(
      selectedNotification.channelId,
    );

    const rowData: NotificationTableRowData[] = [];
    const deletedFilesRowData = [];
    // get id's of file related to notification
    let notificationFileIds: string[] = [];

    // for file upload notifications
    if (isFileUploadNotification) {
      if (selectedNotificationDetails) {
        selectedNotificationDetails.items.forEach(
          (n: InboxNotificationDetailItem) => {
            const fileId = n.resourceId;
            if (fileId === 'default') return; // notification group itself has the 'default' id and we can ignore that

            // checking if the notification file is deleted or not
            if (n.isDeleted) {
              // to indicated a deleted file add a row in notification table
              // with name 'File was deleted'
              deletedFilesRowData.push({
                rowId: fileId,
                name: 'File was deleted',
                location: '',
                isDeleted: true,
              });
              return;
            }

            notificationFileIds.push(fileId);
          },
        );
      }
    } else {
      // for notifications which are not grouped
      // we know there will be only 1 file in those notifications
      const fileId = selectedNotification.data.split('/').at(2);
      // check if the file is deleted
      if (
        selectedNotification.data.includes(DELETED_FILE_NOTIFICATION_STATUS)
      ) {
        deletedFilesRowData.push({
          rowId: fileId,
          name: 'File was deleted',
          location: '',
          isDeleted: true,
        });
      } else if (fileId) {
        notificationFileIds = [fileId];
      }
    }

    const notificationFiles = allChannelFiles.filter((n) =>
      notificationFileIds?.includes(n.id),
    );

    notificationFiles.forEach((file) => {
      const { fields, owner } = file;

      // avoiding folders
      if (fields.fileKey === FOLDER_FILE_KEY) return;
      if (!owner) return;

      let fileExtension = '';
      let fileUrl: string | undefined = undefined;

      // generating file name
      let name = getFileNameFromFileKey(fields.fileKey);
      // except for file links
      // add file extension in file name
      if (!fields.fileUrl) {
        fileExtension = getExtensionFromFileKey(fields.fileKey); // file extension
        name = `
           ${name}${fileExtension}`;
      }

      if (fields.fileUrl) {
        fileExtension = LINK_FILE_EXTENSION;
        ({ fileUrl } = fields);
      }

      // for file upload notification
      // we will show file owner info in the "From" column
      // and for Esign complete notification we will show
      // notification sender user info in "Signed by" column
      const userInfo =
        selectedNotification.notificationGroup === ENTITIES.NOTIFY_FILE_UPLOADED
          ? {
              userName: `${owner?.fields.givenName} ${owner?.fields.familyName}`,
              ...owner,
            }
          : {
              userName: selectedNotification.avatarInfo?.name,
              fields: {
                avatarImageUrl: selectedNotification.avatarInfo?.imageUrl,
                ...selectedNotification.avatarInfo,
              },
            };

      // get file size from notification item data
      const fileData = selectedNotificationDetails?.items.find(
        (fileNotification) =>
          fileNotification.resourceId === file.id &&
          !fileNotification.isDeleted,
      );
      const fileSize = fileData?.fileSize || 0;
      const fileIdentityId = file?.identityId || '';
      const item = {
        rowId: file.id,
        fileKey: file.fields.fileKey,
        fileIdentityId,
        name,
        fileExtension: fileExtension.replace('.', '').toLowerCase(), // removing "." from extension names e.g ".pdf" > "pdf"
        fileUrl: fileUrl,
        location: file.fields.path || '',
        channelId: selectedNotification.channelId,
        email: userInfo.fields.email,
        fallbackColor: userInfo.fields.fallbackColor,
        picture: userInfo.fields.avatarImageUrl,
        size: fileSize,
        ...userInfo,
      };

      rowData.push(item as NotificationTableRowData);
    });

    setNotificationTableRowData([
      ...rowData,
      ...deletedFilesRowData,
    ] as Array<NotificationTableRowData>);
    setFilesNotificationDetailsDataReady(true);
  };

  /**
   * This effect will setup notification table data
   * it listens to the selected notification details
   * fetched from the API and then setup the table data
   * based on that.
   */
  React.useEffect(() => {
    setupNotificationDetailsView();
  }, [selectedNotificationDetails, selectedNotification?.id]);

  const notificationDetailsContentRenderer = () => {
    if (!selectedNotification) return null;
    // this will be replaced by checking event type
    switch (selectedNotification.notificationEventType) {
      // TODO(backfill-9984): remove the forms.completed case. This was an old name that is not used anymore
      case 'formResponse.completed':
      case 'forms.completed': {
        const { resourceId: formId, refId: responseRefId } =
          selectedNotification;

        return (
          <NotificationDetailsContainer
            selectedNotificationDetails={selectedNotificationDetails}
            selectedNotification={selectedNotification}
          >
            <FormResponseDetail
              detailOptions={{
                formId,
                responseRefId,
              }}
              isNotificationPreview
            />
          </NotificationDetailsContainer>
        );
      }
      case 'invoice.paid': {
        return (
          <>
            <NotificationDetailsContainer
              selectedNotification={selectedNotification}
              selectedNotificationDetails={selectedNotificationDetails}
            >
              {invoiceS3AssetUrl && (
                <NotificationPdfViewer
                  pdfUrl={invoiceS3AssetUrl}
                  previewContainerRef={previewContainerRef}
                />
              )}
            </NotificationDetailsContainer>
          </>
        );
      }

      case 'files.new':
      case 'files.created':
      case 'esign.completed':
      case 'contract.signed':
        const notificationGroup =
          selectedNotification.notificationGroup as NotificationGroups;
        // notificationGroup is only present in the older e-signature notifications
        // todo(notification-center): remove the check for notificationGroup once flag is removed
        if (notificationGroup) {
          return (
            <>
              <NotificationDetailsContainer
                selectedNotificationDetails={selectedNotificationDetails}
                selectedNotification={selectedNotification}
              >
                <div style={{ height: '100%' }}>
                  <InboxNotificationTable
                    rowData={notificationTableRowData}
                    // since old e-sig notifications do not have a notificationEventType
                    // and since now the inbox notification table accepts a notificationEventType
                    // then we need to map the notificationGroup to a notificationEventType.
                    notificationEventType={
                      LegacyNotificationTypeToNotificationGroup[
                        selectedNotification.notificationGroup as NotificationGroups
                      ] as NotificationEventType
                    }
                  />
                </div>
              </NotificationDetailsContainer>
            </>
          );
        }

        return (
          <>
            <NotificationDetailsContainer
              selectedNotificationDetails={selectedNotificationDetails}
              selectedNotification={selectedNotification}
            >
              {contractS3AssetUrl && (
                <NotificationPdfViewer
                  pdfUrl={contractS3AssetUrl}
                  previewContainerRef={previewContainerRef}
                />
              )}
            </NotificationDetailsContainer>
          </>
        );

      case 'customApp.action':
        const appName =
          (extensionsSettings &&
            selectedNotificationDetails &&
            extensionsSettings[selectedNotificationDetails?.resourceId]
              ?.name) ||
          '';
        const queryParams = new URLSearchParams({
          id: selectedNotificationDetails?.resourceId ?? '',
          ...(selectedNotificationDetails?.deliveryTargets?.inProduct
            ?.ctaParams ?? {}),
        });
        return (
          <>
            <NotificationDetailsContainer
              selectedNotification={selectedNotification}
              selectedNotificationDetails={selectedNotificationDetails}
            >
              <div style={{ height: '100%' }}>
                <RegularCardBox>
                  <div className={classes.customAppContentContainer}>
                    <MemoBaseTypography fontType="13Regular">
                      {
                        selectedNotificationDetails?.deliveryTargets?.inProduct
                          ?.body
                      }
                    </MemoBaseTypography>

                    <Button
                      variant="outlined"
                      htmlId="cta"
                      className={classes.customAppCtaButton}
                      onClick={() => {
                        navigate(
                          `${CLIENT_APPS_PAGE.path}?${queryParams.toString()}`,
                        );
                      }}
                    >
                      <MemoBaseTypography fontType="12Medium">
                        Open {appName}
                      </MemoBaseTypography>
                    </Button>
                  </div>
                </RegularCardBox>
              </div>
            </NotificationDetailsContainer>
          </>
        );

      default:
        return null;
    }
  };

  const showTimeline =
    selectedNotificationDetails &&
    selectedNotificationDetails.timeline &&
    selectedNotificationDetails.timeline.length > 0;

  // this method handle deleting a notification from
  // the notification details page toolbar.
  const handleDeleteNotification = () => {
    deleteNotification();
  };

  const { handleExportForm: exportForm } = useExportFormResponse(
    getFormCompletedResourceIds(selectedNotification),
  );

  // this method handles exporting a notification form
  // TODO: implement this method later
  const handleExportForm = () => {
    exportForm({
      ref: selectedNotification?.refId || '',
    });
  };

  const handleDownloadNotificationAsset = async () => {
    if (!selectedNotification) {
      return;
    }
    if (selectedNotificationDetails?.assetS3Key) {
      const fileName =
        selectedNotification.notificationEventType === 'invoice.paid'
          ? `Invoice-${
              selectedNotificationDetails.additionalFields?.invoiceNumber ||
              selectedNotificationDetails.resourceId
            }`
          : getFileNameFromFileKey(selectedNotificationDetails.assetS3Key);

      // todo @akash remove this once events are backfilled
      const isContractSignedEvent =
        selectedNotificationDetails.event === 'esign.completed' ||
        selectedNotificationDetails.event === 'contract.signed';
      await FileUtils.downloadFileFromUrl(
        isContractSignedEvent ? contractS3AssetUrl : invoiceS3AssetUrl,
        selectedNotificationDetails.assetS3Key,
        fileName,
      );
    }
  };

  // when no notification is selected
  // render empty page
  if (!selectedNotification) return <InboxEmptyState />;

  return (
    <div className={classes.root} data-testid="inbox-selected-notification">
      <ChannelContentToolbar
        title={selectedNotification.description}
        onBackClick={() => {
          history.replace({
            search: '',
          });
          // In `InboxSidebar` component, here's a useEffect that runs again and set the selectedNotification back to active item id because state is async and there's still active channelId
          // So we need to wait for query to reset before setting selectedNotification to null
          setTimeout(() => {
            setSelectedNotification(null);
          }, 100);
        }}
        AdditionalSlotsComponents={[
          <NotificationDetailsHeaderActions
            onDeleteNotificationClick={handleDeleteNotification}
            onExportFormClick={handleExportForm}
            onDownloadAssetClick={handleDownloadNotificationAsset}
            selectedNotification={selectedNotification}
            s3AssetUrl={
              selectedNotification.notificationEventType === 'invoice.paid'
                ? invoiceS3AssetUrl
                : contractS3AssetUrl
            }
          />,
        ].concat(
          showTimeline
            ? [
                <RightSidebarToggle
                  isSidebarOpen={isSidebarOpen}
                  key="timeline-toggle"
                  data-testid="timeline-toggle"
                  className={classes.rightSidebarToggle}
                  onToggle={(isOpen) => {
                    dispatch(toggleRightSidebar({ isOpen }));
                  }}
                />,
              ]
            : [],
        )}
      />
      <LoadingWrapper
        fullHeight
        isLoading={
          selectedNotificationDetailsLoading ||
          (!filesNotificationDetailsDataReady &&
            (selectedNotification.notificationEventType === 'files.new' ||
              selectedNotification.notificationEventType === 'files.created'))
        }
        hideContentWhileLoading
      >
        <div className={classes.previewContainer} ref={previewContainerRef}>
          {notificationDetailsContentRenderer()}
        </div>
        {showTimeline && (
          <RightSidebar
            sticky={false}
            width={NOTIFICATION_TIMELINE_DRAWER_WIDTH}
            showOnMount
          >
            <NotificationTimeline
              events={selectedNotificationDetails.timeline}
            />
          </RightSidebar>
        )}
      </LoadingWrapper>
    </div>
  );
};
