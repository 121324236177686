import IFlagsmith from 'flagsmith';
import { Flags } from 'flagsmith-nodejs';
import React from 'react';

// sort flags alphabetically to match flag-smith order
export const DefaultFlagsContext = {
  // These two are permanent flags, we use these to rollout flags
  // to our dogfood and launchpad segments.
  EnableAlphaApps: false,
  EnableBetaApps: false,

  EnableAppsApiMutations: false,
  EnableMobileFriendlyBillingForms: false,
  EnableOneOffContract: false,
  EnableRemoveAdvancedSettings: false,
  GoogleLoginForInternalUser: false,
  OnboardingDisabledReason: '',
  EnableCompaniesCrm: false,
  EnableManyCompanies: false,
  EnableCompanyDetailsMarketplaceApps: false,
  EnableNewIUHomeWelcomeBlurb: false,
  EnablePaymentLinksManagement: false,
  EnablePaymentLinksPrivate: false,
  EnablePaymentLinksPublic: false,
  EnableNotes: false,
  EnableMagicLinks: false,
  EnableProductWebhooks: false,
  EnableInvoiceWebhooks: false,
  EnableSubscriptionWebhooks: false,
  EnableCopilotAppCallout: false,
  EnableClientAuthSettings: false,
  EnableInputErrorIcon: false,
  EnableDesignSystemStatusComponent: false,
};

export type FlagContextType = typeof DefaultFlagsContext;

export const getServerFlagsContextValue = (flags: Flags) => ({
  GoogleLoginForInternalUser: flags.isFeatureEnabled(
    'google_login_for_internal_user',
  ),
  EnableAlphaApps: flags.isFeatureEnabled('enable_alpha_apps'),
  EnableBetaApps: flags.isFeatureEnabled('enable_beta_apps'),
  EnableOneOffContract: flags.isFeatureEnabled('one-off-contract'),
  OnboardingDisabledReason: flags.getFeatureValue('onboarding_disabled'),
  EnableAppsApiMutations: flags.isFeatureEnabled('enable_apps_api_mutations'),
  EnableMobileFriendlyBillingForms: flags.isFeatureEnabled(
    'mobile_friendly_billing_forms',
  ),
  EnableRemoveAdvancedSettings: flags.isFeatureEnabled(
    'remove_advanced_settings',
  ),
  EnableNewIUHomeSubscriptionAnalytics: flags.isFeatureEnabled(
    'enable_new_iu_home_subscription_analytics',
  ),
  EnableCompaniesCrm: flags.isFeatureEnabled('enable_companies_crm'),
  EnableManyCompanies: flags.isFeatureEnabled('enable_many_companies'),
  EnableCompanyDetailsMarketplaceApps: flags.isFeatureEnabled(
    'enable_company_details_marketplace_apps',
  ),
  EnablePaymentLinksManagement: flags.isFeatureEnabled(
    'payment-links-management',
  ),
  EnablePaymentLinksPrivate: flags.isFeatureEnabled('payment-links-private'),
  EnablePaymentLinksPublic: flags.isFeatureEnabled('payment-links-public'),
  EnableNotes: flags.isFeatureEnabled('notes'),
  EnableMagicLinks: flags.isFeatureEnabled('enable_magic_links'),
  EnableProductWebhooks: flags.isFeatureEnabled('product_webhooks'),
  EnableInvoiceWebhooks: flags.isFeatureEnabled('invoice_webhooks'),
  EnableSubscriptionWebhooks: flags.isFeatureEnabled('subscription_webhooks'),
  EnableCopilotAppCallout: flags.isFeatureEnabled('enable_copilot_app_callout'),
  EnableClientAuthSettings: flags.isFeatureEnabled(
    'enable_client_auth_settings',
  ),
  EnableInputErrorIcon: flags.isFeatureEnabled('input_error_icon'),
  EnableDesignSystemStatusComponent: flags.isFeatureEnabled(
    'design_system_status_component',
  ),
});

export const getClientFlagsContextValue = (flags: typeof IFlagsmith) => ({
  GoogleLoginForInternalUser: flags.hasFeature(
    'google_login_for_internal_user',
  ),
  EnableAlphaApps: flags.hasFeature('enable_alpha_apps'),
  EnableBetaApps: flags.hasFeature('enable_beta_apps'),
  EnableOneOffContract: flags.hasFeature('one-off-contract'),
  OnboardingDisabledReason: flags.getValue('onboarding_disabled'),
  EnableAppsApiMutations: flags.hasFeature('enable_apps_api_mutations'),
  EnableNewIUHomeWelcomeBlurb: flags.hasFeature(
    'enable_new_iu_home_welcome_blurb',
  ),
  EnableMobileFriendlyBillingForms: flags.hasFeature(
    'mobile_friendly_billing_forms',
  ),
  EnableRemoveAdvancedSettings: flags.hasFeature('remove_advanced_settings'),
  EnableNewIUHomeSubscriptionAnalytics: flags.hasFeature(
    'enable_new_iu_home_subscription_analytics',
  ),
  EnableCompaniesCrm: flags.hasFeature('enable_companies_crm'),
  EnableManyCompanies: flags.hasFeature('enable_many_companies'),
  EnableCompanyDetailsMarketplaceApps: flags.hasFeature(
    'enable_company_details_marketplace_apps',
  ),
  EnablePaymentLinksManagement: flags.hasFeature('payment-links-management'),
  EnablePaymentLinksPrivate: flags.hasFeature('payment-links-private'),
  EnablePaymentLinksPublic: flags.hasFeature('payment-links-public'),
  EnableNotes: flags.hasFeature('notes'),
  EnableMagicLinks: flags.hasFeature('enable_magic_links'),
  EnableProductWebhooks: flags.hasFeature('product_webhooks'),
  EnableInvoiceWebhooks: flags.hasFeature('invoice_webhooks'),
  EnableSubscriptionWebhooks: flags.hasFeature('subscription_webhooks'),
  EnableCopilotAppCallout: flags.hasFeature('enable_copilot_app_callout'),
  EnableClientAuthSettings: flags.hasFeature('enable_client_auth_settings'),
  EnableInputErrorIcon: flags.hasFeature('input_error_icon'),
  EnableDesignSystemStatusComponent: flags.hasFeature(
    'design_system_status_component',
  ),
});

export const FlagsContext = React.createContext(DefaultFlagsContext);
