import React, { useContext, useMemo, useState } from 'react';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { ColDef, GridApi } from '@ag-grid-community/core';
import Layout, { PageLayout } from 'src/legacy/components/Layout';
import { CONTRACTS_PAGE } from 'src/constants';
import history from 'src/history';
import { TableToolbar } from 'src/legacy/components/UI/Pages/TableToolbar';
import {
  CreateButton,
  PageToolbar,
  PageToolbarAction,
} from 'src/legacy/components/UI';
import { BaseTabsHeader } from 'src/legacy/components/UI/BaseTab';
import { RouteContext } from 'src/context';
import { TemplateListTable } from 'src/legacy/components/Contracts/TemplateListTable';
import { ensureUnreachable } from 'src/utils/common_utils';
import { TableSearch } from 'src/legacy/components/SearchInput/Searchbox';
import { AgGridContext } from 'src/legacy/components/AgGrid';
import { Action } from '../Dropdowns';
import { ClientContractsTable } from './ClientContractsTable';
import { SubmissionListTable } from './SubmissionsListTable';
import {
  useGetContractTemplatesQuery,
  useGetContractsQuery,
} from 'src/services/api';
import {
  processContractTableCellCallback,
  processContractTableHeaderCallback,
} from 'src/utils/ContractUtils';
import { usePageTitle } from 'src/hooks/usePageTitle';
import { NonDesktopActionTooltip } from 'src/legacy/components/Tooltip';
import { DateUtils } from 'src/utils';
import { Icon } from 'copilot-design-system';
import { useAppSelector } from 'src/hooks/useStore';

export enum ContractPageTabs {
  Templates = 'templates',
  Submissions = 'submissions',
}

type ContractQueryParams = {
  view: ContractPageTabs;
};

const PAGE_TAB_HEADERS: Array<{ key: ContractPageTabs; label: string }> = [
  {
    key: ContractPageTabs.Templates,
    label: 'Templates',
  },
  {
    key: ContractPageTabs.Submissions,
    label: 'Submissions and requests',
  },
];

/**
 * Render the Contracts Table Page.
 * @returns {JSX.Element} The page component for displaying contracts in a table.
 */
export const ContractsTablePage = () => {
  const isClient = useAppSelector((state) => state.user.isClient);
  const clientUserId = useAppSelector((state) => state.user.id);
  const { query } = useContext(RouteContext);
  const { view } = query as ContractQueryParams;
  const theme = useTheme();
  const isDesktopScreen = useMediaQuery(theme.breakpoints.up('md'));
  const { data: templates } = useGetContractTemplatesQuery(undefined, {
    skip: isClient,
  });
  const { data: submissions } = useGetContractsQuery({});

  const [gridApi, setGridApi] = React.useState<GridApi>();

  const gridOptions = React.useMemo(() => ({ gridApi, setGridApi }), [gridApi]);
  const { pageTitle } = usePageTitle(CONTRACTS_PAGE.label, 'contracts');

  // State to track the active tab.
  // Initially, it will be set to the value from the query parameter 'view'.
  // If the query parameter is not present, fallback to the templates list tab.
  const [activeTabKey, setActiveTabKey] = useState(
    view || ContractPageTabs.Templates,
  );

  const handleTabChange = (key: ContractPageTabs) => {
    setActiveTabKey(key);
    history.push(`${CONTRACTS_PAGE.path}?view=${key}`);
  };

  const handleClickNewContract = () => {
    history.push(`${CONTRACTS_PAGE.path}/edit`);
  };

  const renderClientTable = () => (
    <ClientContractsTable clientUserId={clientUserId} />
  );

  const renderActiveTabPageContent = () => {
    switch (activeTabKey) {
      case ContractPageTabs.Templates:
        return <TemplateListTable />;

      case ContractPageTabs.Submissions:
        return <SubmissionListTable />;
      default:
        return ensureUnreachable(activeTabKey);
    }
  };

  /**
   * This method exports the table data into CSV format.
   * Only the data from the currently active tab is exported.
   */
  const handleExportData = () => {
    const tableColumns = gridApi?.getColumnDefs();
    if (!tableColumns) return;
    // Retrieves column keys for the CSV export.
    const csvColumnKeys = tableColumns
      .map((c: ColDef) => c.field)
      .filter(Boolean);

    gridApi?.exportDataAsCsv({
      fileName: `contracts_${DateUtils.formatDate(new Date().toString())}`,
      suppressQuotes: true,
      columnKeys: csvColumnKeys,
      // Callback to customize the CSV header.
      processHeaderCallback: processContractTableHeaderCallback,
      processCellCallback: processContractTableCellCallback,
    });
  };

  /**
   * Determine whether to hide page header actions.
   * Page header actions should only be visible to internal users.
   * @returns {boolean} True if the actions should be hidden, otherwise false.
   */
  const shouldHideToolbarActions = isClient;

  /**
   * Checks if the table has at least one record to show.
   * Header actions such as search and export are rendered only when there is data available.
   */
  const hasRecordsToShow = useMemo(() => {
    switch (activeTabKey) {
      case ContractPageTabs.Templates:
        return templates?.length;

      case ContractPageTabs.Submissions:
        return submissions?.length;
      default:
        return ensureUnreachable(activeTabKey);
    }
  }, [submissions, templates, activeTabKey]);

  const toolbarActions: Action[] = [
    {
      name: 'Export',
      icon: <Icon icon="Export" />,
      onClick: handleExportData,
    },
  ];

  // Hide the page tabs when there is no data
  // to show on both tabs i.e "Templates" and "Submissions and requests"
  const hideTabHeaders =
    !(submissions && submissions.length > 0) &&
    !(templates && templates.length > 0);

  return (
    <Layout
      headerComponent={
        <TableToolbar>
          <PageToolbar
            title={pageTitle}
            actionButtons={
              shouldHideToolbarActions
                ? []
                : [
                    <PageToolbarAction
                      key="contracts"
                      actions={hasRecordsToShow ? toolbarActions : []}
                      createButton={
                        hasRecordsToShow ? (
                          <NonDesktopActionTooltip>
                            <CreateButton
                              htmlId="btn-create-contracts-template"
                              onClick={handleClickNewContract}
                              disabled={!isDesktopScreen}
                            >
                              New contract
                            </CreateButton>
                          </NonDesktopActionTooltip>
                        ) : null
                      }
                    >
                      {hasRecordsToShow ? <TableSearch /> : null}
                    </PageToolbarAction>,
                  ]
            }
          />
        </TableToolbar>
      }
    >
      <PageLayout routeAccessCheck="allowContractsPage" noSpace>
        <div className="overflow-y-hidden h-full">
          {!isClient && !hideTabHeaders && (
            <BaseTabsHeader
              tabs={PAGE_TAB_HEADERS}
              activeTabKey={activeTabKey}
              onTabChange={(key) => handleTabChange(key as ContractPageTabs)}
            />
          )}
          <AgGridContext.Provider value={gridOptions}>
            {!isClient ? renderActiveTabPageContent() : renderClientTable()}
          </AgGridContext.Provider>
        </div>
      </PageLayout>
    </Layout>
  );
};
