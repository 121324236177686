import React from 'react';
import parse, {
  DOMNode,
  domToReact,
  HTMLReactParserOptions,
  Element as HTMLReactParserElement,
} from 'html-react-parser';
import { Body, Heading } from 'copilot-design-system';

interface HtmlRendererProps {
  html: string;
  renderOptions?: HTMLReactParserOptions; // Optional custom render options
}

export const HtmlRenderer: React.FC<HtmlRendererProps> = ({
  html,
  renderOptions,
}) => {
  const defaultRenderOptions: HTMLReactParserOptions = {
    replace: (node) => {
      if (
        node instanceof HTMLReactParserElement &&
        node.type === 'tag' &&
        node.attribs
      ) {
        const { name, children, attribs } = node;
        switch (name) {
          case 'h3':
            return (
              <Heading size="xl" className="mt-5">
                {domToReact(children as DOMNode[], defaultRenderOptions)}
              </Heading>
            );
          case 'p':
            return (
              <Body size="lg" tag="p" className="mt-3 mb-0">
                {domToReact(children as DOMNode[], defaultRenderOptions)}
              </Body>
            );
          case 'li':
            return (
              <li {...attribs} className="text-lg text-black-heading mt-1">
                {domToReact(children as DOMNode[], defaultRenderOptions)}
              </li>
            );
          case 'a':
            return (
              <a
                {...attribs}
                target="_blank"
                className="text-lg text-black-heading underline"
              >
                {domToReact(children as DOMNode[], defaultRenderOptions)}
              </a>
            );

          case 'img':
            return (
              <img
                {...attribs}
                className="w-full h-auto mt-3 mx-0 border border-solid rounded-4 border-non-hover-border"
              />
            );

          case 'figure':
            return (
              <figure {...attribs} className="w-full h-auto mt-3 mx-0">
                {domToReact(children as DOMNode[], defaultRenderOptions)}
              </figure>
            );
          case 'ol':
            return (
              <ol {...attribs} className="ml-4 mt-3">
                {domToReact(children as DOMNode[], defaultRenderOptions)}
              </ol>
            );
          case 'ul':
            return (
              <ul {...attribs} className="ml-4 mt-3">
                {domToReact(children as DOMNode[], defaultRenderOptions)}
              </ul>
            );
          case 'video':
            return (
              <video
                controls
                className="w-full h-full rounded-4 border border-solid border-non-hover-border"
              >
                <source src={attribs.src} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            );

          default:
            return node;
        }
      } else {
        return null;
      }
    },
  };

  const mergedRenderOptions: HTMLReactParserOptions = {
    ...defaultRenderOptions,
    ...renderOptions,
  };

  return (
    // The following class names target and hide default video control elements
    // that are auto-injected by the HTML fetched from the latest release API.
    // This ensures a cleaner UI by removing redundant controls.
    <div className="[&_.kg-video-player-container]:hidden [&_.kg-video-overlay]:hidden">
      {parse(html, mergedRenderOptions)}
    </div>
  );
};
