import React, { MouseEvent } from 'react';
import { MenuItem, IconButton, Theme, makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import BaseTypography from 'src/legacy/components/Text/BaseTypography';
import * as Colors from 'src/theme/colors';
import { Action, BaseActionsMenuProps } from 'src/constants';
import { typography12MediumStyle } from 'src/legacy/components/Text';
import { BaseActionsMenuButton, BaseMenu } from '.';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    // on menu item hover show more action button
    '&:hover .more-button': {
      opacity: 1,
      pointerEvents: 'all',
    },
  },
  delete: {
    color: Colors.red,
    '& svg': {
      color: Colors.red,
    },
  },
  icon: {
    color: Colors.BlackHeadings,
    padding: 0,
    marginRight: theme.spacing(2),
    '& svg': {
      fontSize: 14,
    },
  },
  subtitle: {
    color: Colors.GraySmall,
    ...typography12MediumStyle,
  },
  moreActionButton: {
    opacity: 0,
    pointerEvents: 'none',
    marginLeft: 'auto',
  },
  activeMoreActionButton: {
    opacity: 1,
    pointerEvents: 'all',
  },
}));

interface ActionMenuProps {
  action: Action;
  onClick: (event: React.MouseEvent<HTMLLIElement>, action: Action) => void;
}

export const ActionMenuItem: React.FC<ActionMenuProps> = React.memo(
  ({ action, onClick }) => {
    const classes = useStyles();
    const addDeleteClass = !action.isDisabled && action.isDelete;
    const [actionMenuOptions, setActionMenuOptions] =
      React.useState<BaseActionsMenuProps | null>(null);

    // when user clicks on the more action button
    const handleOpenMoreActionsMenu = (menuOptions: BaseActionsMenuProps) => {
      setActionMenuOptions({
        ...menuOptions,
        menuProps: {
          ...menuOptions.menuProps,
          open: Boolean(menuOptions.menuProps?.open),
          onClose: (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
            e.stopPropagation(); // this is to avoid event bubbling two popovers are in open state
            setActionMenuOptions(null);
          },
        },
      });
    };

    // when user clicks on the menu item
    const handleMenuItemClick = React.useCallback(
      (event: MouseEvent<HTMLLIElement>) => {
        event.stopPropagation();
        onClick(event, action);
      },
      [action],
    );

    return (
      <MenuItem
        data-testid={`action-${action.name}`}
        key={action.name}
        onClick={handleMenuItemClick}
        disabled={action.isDisabled || action.isSubtitle}
        style={action.isSubtitle ? { opacity: 1 } : undefined}
        className={classNames(
          classes.root,
          action.classes ? action.classes.join(' ') : undefined,
        )}
      >
        {action.icon && (
          <IconButton
            className={classNames(classes.icon, 'h-[14px] w-[14px]', {
              [classes.delete]: addDeleteClass,
            })}
            style={action.iconStyle}
          >
            {action.icon}
          </IconButton>
        )}
        {action.avatar && <div className="mr-4">{action.avatar}</div>}
        <BaseTypography
          className={classNames({
            [classes.delete]: addDeleteClass,
            [classes.subtitle]: action.isSubtitle,
          })}
          noWrap
        >
          {action.name}
        </BaseTypography>

        {action.subActions && action.subActions.length > 0 ? (
          <div
            className={classNames(classes.moreActionButton, 'more-button', {
              [classes.activeMoreActionButton]: Boolean(
                actionMenuOptions?.menuProps?.open,
              ),
            })}
          >
            <BaseActionsMenuButton
              actions={action.subActions}
              onActionMenuClick={handleOpenMoreActionsMenu}
            />
          </div>
        ) : null}
        {actionMenuOptions && (
          <BaseMenu
            variant="skinny"
            preventClickEventPropagation
            {...actionMenuOptions}
          />
        )}
      </MenuItem>
    );
  },
);
