import React from 'react';
import { PostOrPage } from '@tryghost/content-api';
import { RouteContext } from 'src/context';
import { useLatestRelease } from 'src/hooks/useLatestRelease';
import { useAppSelector } from 'src/hooks/useStore';
import { WhatsNewModal } from 'src/legacy/components/WhatsNew/WhatsNewModal';
import { useGetDismissedLatestReleaseQuery } from 'src/services/api/LatestReleaseApi';
import { selectCurrentUser } from 'src/store/storeUtils';
import { DateUtils } from 'src/utils';

export const isReleasePostOlderThan14Days = (
  latestReleasePost: PostOrPage | null,
) => {
  if (!latestReleasePost?.published_at) return true;

  const publishedDate = new Date(latestReleasePost.published_at);

  const daysSinceRelease = DateUtils.getDiffFromDate(publishedDate, 'days');
  return daysSinceRelease > 14;
};

export const WhatsNew = () => {
  // this is a query param override to show the what's new modal.
  // This will ignore the the dismissal state and also the time window
  // required to see the modal.
  const {
    query: { showLatestRelease = 'false' },
  } = React.useContext(RouteContext);
  const isClient = useAppSelector((state) => state.user.isClient);
  const currentUserCreatedDate = useAppSelector(selectCurrentUser)?.createdDate;

  const showLatestReleaseOverride = showLatestRelease === 'true';

  const userCanSeeWhatsNew =
    !isClient &&
    (showLatestReleaseOverride ||
      DateUtils.getDaysDiffOfDate(new Date(currentUserCreatedDate as string)) >
        7);

  const latestReleasePost = useLatestRelease(!userCanSeeWhatsNew);

  const {
    data: latestReleaseDismissal,
    isLoading: isLoadingDismissedLatestRelease,
  } = useGetDismissedLatestReleaseQuery(null, { skip: !userCanSeeWhatsNew });

  // this determines whether to show the latest release card or not
  // it actually compares the latest release post id with the latest release dismissal
  // state. If the latest release id is in dismissal object do not show it.
  // Also check for other conditions:
  // - The post is not older than 14 days
  // - The post has html content
  // - The post is not loading
  const showLatestReleaseSection =
    (showLatestReleaseOverride ||
      (userCanSeeWhatsNew &&
        latestReleaseDismissal?.structFields.latest_release_dismissed !==
          latestReleasePost?.id &&
        !isReleasePostOlderThan14Days(latestReleasePost))) &&
    latestReleasePost?.html &&
    !isLoadingDismissedLatestRelease;

  return showLatestReleaseSection ? (
    <WhatsNewModal latestReleasePost={latestReleasePost} />
  ) : null;
};
